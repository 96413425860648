<template>
  <div>
    <v-container fluid class="py-6">
      <v-row>
        <v-col cols="12">
          <v-card class="card-shadow border-radius-xl">
            <table-volunteers></table-volunteers>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import TableVolunteers from "@/views/Backoffice/Volunteers/Widgets/TableVolunteers";

export default {
  name: "backoffice-requests-list",
  components: {
    TableVolunteers,
  },
  data() {
    return {};
  },
};
</script>
