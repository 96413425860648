<template>
  <div>
    <v-card class="card-shadow border-radius-xl mt-6">
      <v-card-text class="px-0 py-0">
        <v-data-table
          :headers="headers"
          :items="volunteers"
          :search="search"
          class="table"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          mobile-breakpoint="0"
        >
          <template v-slot:top>
            <v-toolbar flat height="80">
              <v-row>
                <v-col cols="6" md="10" lg="8">
                  <v-text-field
                    hide-details
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    v-model="search"
                    :placeholder="'Search'|trans"
                  >
                    <template slot="prepend-inner">
                      <v-icon color="#adb5bd" size=".875rem"
                        >fas fa-search
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="6" md="2" lg="4" class="text-right">
                  <v-btn
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="
                      font-weight-bold
                      text-capitalize
                      ms-auto
                      btn-default
                      bg-gradient-default
                      py-3
                      px-6
                      ms-3
                    "
                    @click="openVolunteerModal()"
                    ><span v-if="!$vuetify.breakpoint.mobile"
                      >{{ 'Invite volunteer'|trans }}</span
                    >
                    <span v-else>{{ 'Invite'|trans }}</span>
                  </v-btn>
                </v-col>
              </v-row>

              <volunteer-form
                :show-modal="isVolunteerModalOpen"
                :volunteer="newVolunteer"
                @closed="closeVolunteerModal"
                @created="getVolunteersList"
              ></volunteer-form>
            </v-toolbar>
          </template>

          <template v-slot:item.name="{ item }">
            <span class="my-2 text-xs text-body font-weight-bold">{{
              item.name
            }}</span>
          </template>

          <template v-slot:item.email="{ item }">
            <div class="d-flex align-center">
              <span class="text-body text-xs">{{ item.email }}</span>
            </div>
          </template>

          <template v-slot:item.servicesList="{ item }">
            <span class="my-2 text-xs text-body font-weight-bold">{{
              item.servicesList.join(", ")
            }}</span>
          </template>
          <template v-slot:item.category.name="{ item }">
            <span class="my-2 text-xs text-body font-weight-bold">{{
                item.category ? item.category.name: ''
            }}</span>
          </template>

          <template v-slot:item.spokenLanguages="{ item }">
            <span class="my-2 text-xs text-body font-weight-bold">
              {{
                item.spokenLanguages
                  .map(function (lang) {
                    return spokenLanguages(lang);
                  })
                  .join(",")
              }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
// import volunteers from "./Volunteers";
import VolunteerForm from "@/views/Backoffice/Volunteers/FormModal";

export default {
  name: "table-volunteers",
  components: {
    VolunteerForm,
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      volunteers: [],
      search: "",
      isVolunteerModalOpen: false,
      newVolunteer: {
        name: "",
        services: [],
        email: "",
      },
    };
  },
  mounted() {
    this.getVolunteersList();
  },
  watch: {},
  methods: {
    spokenLanguages: function (val) {
      return this.$options.filters.spokenLanguage(val);
    },
    openVolunteerModal() {
      this.isVolunteerModalOpen = true;
    },
    closeVolunteerModal() {
      this.isVolunteerModalOpen = false;
    },
    getVolunteersList() {
      fetch(process.env.VUE_APP_API_URL + "/users/volunteers", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": this.authToken,
          "Accept-Language": this.$store.getters.getLocale,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.type !== "success") {
            this.localAlert = {
              type: "error",
              color: "red",
              message: data.message ? data.message : this.$translator.trans("Unable to fetch list!"),
            };

            return false;
          }

          this.volunteers = data.users;

          this.volunteers.map(function (volunteer) {
            volunteer.servicesList = volunteer.services.map(function (service) {
              return service.name;
            });
          });
        })
        .catch((error) => {
          console.error("Error:", error);

          this.localAlert = {
            type: "error",
            color: "red",
            message: error.message ? error.message : this.$translator.trans("Unable to fetch list!"),
          };
        });
    },
  },
  computed: {
    authToken() {
      return this.$store.getters.getAuthToken;
    },
    headers(){
      return [
        {
          text: this.$translator.trans("Full Name"),
          value: "name",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: this.$translator.trans("Email"),
          value: "email",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: this.$translator.trans("Services"),
          value: "servicesList",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: this.$translator.trans("Group"),
          value: "category.name",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: this.$translator.trans("Spoken languages"),
          value: "spokenLanguages",
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ];
    },
  },
};
</script>
