<template>
  <v-dialog v-model="dialog" max-width="500px" @input="(v) => v || close()">
    <v-card class="card-shadow border-radius-xl">
      <div class="card-header-padding card-border-bottom">
        <span class="font-weight-bold text-h5 text-typo mb-0"
          >{{ 'Invite volunteer'|trans }}</span
        >
      </div>
      <v-form v-model="valid" ref="form">
        <v-card-text class="card-padding">
          <v-container class="px-0">
            <v-row v-if="localAlert">
              <v-col cols="12">
                <v-alert :type="localAlert.type" :color="localAlert.color">
                  {{ localAlert.message }}
                </v-alert>
              </v-col>
            </v-row>
            <v-row v-if="validationErrors">
              <v-col cols="12" v-for="validationError in validationErrors">
                <v-alert
                  :type="validationError.type"
                  :color="validationError.color"
                >
                  {{ validationError.message }}
                </v-alert>
              </v-col>
            </v-row>
            <v-text-field
              hide-details
              :rules="validation.required"
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              height="40"
              :placeholder="'Full Name'|trans"
              v-model="volunteer.name"
              class="
                input-style
                font-size-input
                border border-radius-md
                placeholder-lighter
                text-color-light
                mb-4
                required
              "
            >
            </v-text-field>
            <v-text-field
              hide-details
              :rules="validation.required"
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              height="40"
              :placeholder="'Email'|trans"
              v-model="volunteer.email"
              class="
                input-style
                font-size-input
                border border-radius-md
                placeholder-lighter
                text-color-light
                mb-4
              "
            >
            </v-text-field>

            <!--              <v-text-field-->
            <!--                  hide-details-->
            <!--                  :rules="validation.required"-->
            <!--                  outlined-->
            <!--                  background-color="rgba(255,255,255,.9)"-->
            <!--                  color="rgba(0,0,0,.6)"-->
            <!--                  light-->
            <!--                  height="40"-->
            <!--                  placeholder="Yale ID"-->
            <!--                  v-model="volunteer.identityID"-->
            <!--                  class="-->
            <!--                input-style-->
            <!--                font-size-input-->
            <!--                border border-radius-md-->
            <!--                placeholder-lighter-->
            <!--                text-color-light-->
            <!--                mb-4-->
            <!--                required-->
            <!--              "-->
            <!--              >-->
            <!--              </v-text-field>-->
            <AvailableUserCategories
                v-model="volunteer.category"
                classList="
                      input-style
                font-size-input
                border border-radius-md
                placeholder-lighter
                text-color-light
                mb-4
">
            </AvailableUserCategories>
            <v-text-field
              hide-details
              :rules="validation.required"
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              height="40"
              :placeholder="'Graduation year'|trans"
              v-model="volunteer.yearOfStudy"
              class="
                input-style
                font-size-input
                border border-radius-md
                placeholder-lighter
                text-color-light
                mb-4
                required
              "
            >
            </v-text-field>

            <AvailableServices
              v-model="volunteer.services"
              classList="
                      input-style
                font-size-input
                border border-radius-md
                placeholder-lighter
                text-color-light
                mb-4
"
            ></AvailableServices>

            <v-combobox
              :items="availableLanguages"
              :placeholder="'Spoken languages'|trans"
              :rules="validation.required"
              item-text="name"
              item-value="code"
              multiple
              clearable
              :return-object="true"
              v-model="volunteer.spokenLanguages"
              color="rgba(0,0,0,.6)"
              class="
                input-style
                font-size-input
                border border-radius-md
                placeholder-lighter
                text-color-light
                mb-4
              "
              outlined
              hide-details
              light
              dense
            >
            </v-combobox>
          </v-container>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="close"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-secondary
              bg-gradient-light
              py-3
              px-6
            "
            >{{ 'Cancel'|trans }}
          </v-btn>

          <v-btn
            @click="save"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-gradient-default
              py-3
              px-6
            "
            >{{ 'Save'|trans }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import availableLanguages from "@/data/languages.json";
import AvailableServices from "@/components/AvailableServices";
import AvailableUserCategories from "@/components/AvailableUserCategories";

export default {
  name: "volunteer-form",
  props: ["showModal"],
  components: {AvailableUserCategories, AvailableServices },
  data() {
    return {
      valid: false,
      localAlert: null,
      validationErrors: [],
      validation: {
        required: [(v) => !!v || "Is required"],
      },
      volunteer: {
        name: "",
        email: "",
        newPassword: "",
        newPasswordRepeat: "",
        spokenLanguages: [],
        services: [],
        category: null,
        categoryGuid: null,
        yearOfStudy: "",
        identityID: "",
        captcha: null,
      },
      availableServices: [],
      availableLanguages: availableLanguages,
    };
  },
  methods: {
    close() {
      this.reset();
      this.$emit("closed");
    },
    reset() {
      this.$refs.form.reset();
    },
    validate() {
      return this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    save() {
      this.handleRegister();
    },
    handleRegister() {
      this.localAlert = null;
      this.validationErrors = [];

      if (false === this.validate()) {
        //error
        this.localAlert = {
          type: "error",
          color: "red",
          message: this.$translator.trans("Please check the form below for errors!"),
        };
        return false;
      }

      this.isLoading = true;

      let data = this._.cloneDeep(this.volunteer);

      data.services = data.services.map(function (service) {
        return service.guid;
      });
      data.categoryGuid = data.category.guid;
      data.spokenLanguages = data.spokenLanguages.map(function (
        spokenLanguage
      ) {
        return spokenLanguage.code;
      });
      let $this = this;
      fetch(process.env.VUE_APP_API_URL + "/register/volunteer/invited", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": this.authToken,
          "Accept-Language": this.$store.getters.getLocale,
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.type !== "success") {
            this.localAlert = {
              type: "error",
              color: "red",
              message: data.message ? data.message : this.$translator.trans("Registration failed!"),
            };

            if (typeof data.errors !== "undefined") {
              for (let i = 0; i < data.errors.length; i++) {
                let error = data.errors[i];
                $this.validationErrors.push({
                  type: "error",
                  color: "red",
                  message: Object.values(error)[0],
                });
              }
            }

            return false;
          }

          this.$notify({
            group: "global",
            type: "success",
            title: this.$translator.trans("Invitation created successfully!"),
            text: data.message ? data.message : "",
          });

          this.localAlert = {
            type: "success",
            color: "green",
            message: this.$translator.trans("Registration successful! Check your email!"),
          };

          this.$emit("created");

          this.close();
        })
        .catch((error) => {
          console.error("Error:", error);

          this.localAlert = {
            type: "error",
            color: "red",
            message: error.message ? error.message : this.$translator.trans("Registration failed!"),
          };
        });

      this.isLoading = false;
      this.validationErrors = [];
    },
  },
  watch: {},

  computed: {
    authToken() {
      return this.$store.getters.getAuthToken;
    },
    dialog: {
      get: function () {
        return this.showModal;
      },
      // setter
      set: function () {
        // if(!val){
        //   this.onClose();
        // }
      },
    },
  },
};
</script>
